<script lang="ts">
    import { onMount } from 'svelte';
    import Swiper from 'swiper';
    import PostBoxMediaWrapper from '@/elements/Feed/PostBoxMediaWrapper.svelte';
    import PostLocked from '@/elements/Feed/PostLocked.svelte';
    import BackwardChevron from '@/Icons/BackwardChevron.svelte';
    import ForwardChevron from '@/Icons/ForwardChevron.svelte';

    export let post: any;
    export let currentUser: any;
    export let isAppleDevice: string;
    export let data: any;
    export let loggedUserAvailableAmount: number;

    export let isPostUnlocked: () => boolean;
    export let shouldShowLockedContent: () => boolean;

    let swiper: Swiper;

    onMount(() => {
        if (post.attachments.length > 1) {
            swiper = new Swiper('.mySwiper', {
                pagination: {
                    el: '.swiper-pagination',
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        }
    });
</script>

{#if post.attachments.length > 0}
    <div class="post-media">
        {#if isPostUnlocked()}
            {#if post.attachments.length > 1}
                <div class="swiper-container mySwiper pointer-cursor">
                    <div class="swiper-wrapper">
                        {#each post.attachments as attachment (attachment.id)}
                            <div class="swiper-slide">
                                <PostBoxMediaWrapper
                                    {post}
                                    {attachment}
                                    isGallery="{true}"
                                    {currentUser}
                                    {isAppleDevice}
                                />
                            </div>
                        {/each}
                    </div>
                    <div class="swiper-button swiper-button-next text-white">
                        <ForwardChevron />
                    </div>
                    <div class="swiper-button swiper-button-prev text-white">
                        <BackwardChevron />
                    </div>
                    <div class="swiper-pagination"></div>
                </div>
            {:else}
                <PostBoxMediaWrapper
                    {post}
                    attachment="{post.attachments[0]}"
                    isGallery="{false}"
                    {currentUser}
                    {isAppleDevice}
                />
            {/if}
        {:else if shouldShowLockedContent()}
            <PostLocked
                {data}
                {post}
                type="post"
                multi="{post.attachments.length > 1}"
                {currentUser}
                {loggedUserAvailableAmount}
            />
        {:else}
            <PostLocked
                {data}
                {post}
                type="post"
                multi="{post.attachments.length > 1}"
                {currentUser}
                {loggedUserAvailableAmount}
            />
        {/if}
    </div>
{/if}
